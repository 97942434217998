module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://policylibrary.mcnsolutions.com"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","siteUrl":"https://policylibrary.mcnsolutions.com"},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
